import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 31;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>雪が降り凍える中でフライフィッシングをする人たち</li>
        <li>暖房を使わずに火を焚いている</li>
        <li>家の中の方が寒い古民家</li>
        <li>怠け者にぴったりの半分キャンプ</li>
        <li>寒いときにわざわざアウトドアとかしたくない</li>
        <li>なぜか毎回真冬にキャンプすることになってしまう</li>
        <li>キャンプは寝袋で寝るのが苦痛</li>
        <li>だいちゃんのいびき問題</li>
        <li>リュックを背負って寝るいびき対策</li>
        <li>古民家でできる快適な半分キャンプが最高だよね</li>
        <li>キャンプのためにキャンプしたくない</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
